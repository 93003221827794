.my-activity {
    padding: 10px;
}

.activity-tabs button {
    padding: 10px 20px;
    margin-right: 5px;
    background-color: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
}

.activity-tabs button.active {
    color: #007bff;
    border-bottom: 2px solid #007bff;
}

.activity-content {
    margin-top: 20px;
}

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

@media (max-width: 768px) {
    .activity-tabs button {
        width: 100%;
        margin-bottom: 10px;
    }
}



.form-control {
    margin-bottom: 10px;
}

.dropdown-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.dropdown-icon {
    position: absolute;
    right: 15px;
    pointer-events: none;
}

.dropdown-select {
    appearance: none;
    padding-right: 30px;
    background-color: #f8f9fa; /* Add background color */
    border-radius: 5px; /* Add border radius */
    border: 1px solid #ced4da; /* Add border */
}

.dropdown-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.clickable {
    cursor: pointer;
    transition: transform 0.2s ease;
}

.clickable:hover {
    transform: scale(1.05);
}

.switch {
    position: relative;
    display: inline-block;
    width: 30px; /* Reduced width */
    height: 15px; /* Reduced height */
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 15px; /* Adjusted border-radius */
}

.slider:before {
    position: absolute;
    content: "";
    height: 11px; /* Adjusted height */
    width: 11px; /* Adjusted width */
    left: 2px; /* Adjusted position */
    bottom: 2px; /* Adjusted position */
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:checked + .slider:before {
    transform: translateX(15px); /* Adjusted transform */
}

.container2 {
    padding: 5px;
    margin-top: 10px;
    top: 5px;  /* Moves the container 20px upwards */
    position: relative;
}

.dashboard-overview {
    display: flex;
    flex-wrap: wrap; /* Allows cards to wrap to the next line */
    gap: 10px;
    justify-content: space-around; /* Distributes space around items */
    background-color: #f9f9f9;
    padding: 2px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.card {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background: #fff;
    cursor: pointer;
    text-align: center;
    box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}

.dashboard-overview .card.selected {
    border: 2px solid #007bff;
    background-color: #e7f0ff; /* Light blue background */
    color: #007bff; /* Blue text */
}

.card.selected {
    border-color: #007bff;
    background-color: #e7f0ff;
}



.icon {
    font-size: 1.5rem;
}

@media (max-width: 390px) { /* Adjust for iPhone 15 width */
    .dashboard-overview {
        padding: 2px;
    }

    .card {
        flex: 1 1 48%; /* Ensures two cards per row, with each taking slightly less than half the screen width */
        padding: 8px; /* Slightly less padding to save space */
    }

    .icon {
        font-size: 1.2rem; /* Smaller icons */
    }

    h6 {
        font-size: 14px; /* Smaller heading to fit better */
    }

    .lead, small {
        font-size: 12px; /* Smaller text for details to not overwhelm the card */
    }
}

.activity-controls .switch {
    margin-right: 10px;
}

.activity-controls span {
    margin-left: 10px;
}

.clickable {
    cursor: pointer;
}

.custom-radio-size {
    margin-right: 10px;
}

.form-check-inline {
    flex-grow: 1; /* Allows each radio button to fill the space evenly */
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Aligns content to the left within each flex item */
    margin-right: 10px; /* Consistent space between groups */
}

.form-check-label {
    font-size: 14px; /* Appropriate text size */
    line-height: 1.2; /* Good line height for readability */
    text-align: left; /* Aligns text to the left */
}

.form-check-input {
    margin-right: 8px; /* Space between the radio button and the label */
    margin-left: 5px; /* Added to provide initial spacing from container edge or previous element */
}

/* Optional: Media queries to adjust for smaller screens */
@media (max-width: 768px) {
    .form-check-inline {
        justify-content: flex-start; /* Ensures alignment is maintained on small screens */
        margin-right: 5px; /* Smaller margin on smaller screens */
    }
}

.wishlist-card {
    background: linear-gradient(to bottom, #ffffff 0%, #f9f9f9 100%); /* Subtle gradient from white to light grey */
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.15); /* Slightly stronger shadow for more depth */
    border: 1px solid #e0e0e0; /* Light grey border for definition */
    margin: 5px;
    padding: 15px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    cursor: pointer; /* Keeps the cursor as pointer to indicate it’s clickable */
}

.wishlist-name {
    font-size: 16px; /* Slightly smaller font size */
    color: #333333; /* Slightly darker shade for better readability */
    margin-bottom: 5px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.wishlist-description {
    font-size: 14px; /* Slightly smaller font size */
    color: #555555; /* Darker gray for better readability */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* Limit to one line */
    -webkit-box-orient: vertical;
}

.d-flex .item-card {
    margin-right: 15px; /* Right margin of each item */
    margin-left: 15px; /* Left margin of each item */
    margin-bottom: 20px; /* Bottom margin to increase vertical spacing as previously set */
}

/* Adjust the last item in a row to not have a right margin */
.d-flex .item-card:last-child {
    margin-right: 0;
}

@media (max-width: 768px) {
    .d-flex .item-card {
        margin-right: 5px;
        margin-left: 5px;
        margin-bottom: 15px;
    }
}

@media (max-width: 480px) {
    .d-flex {
        justify-content: center; /* Centers items when there is less space */
    }

    .d-flex .item-card {
        margin-right: 2px;
        margin-left: 2px;
        margin-bottom: 10px;
    }
}

.activity-controls {
    display: flex;
    justify-content: center;
    border-bottom: 2px solid #ccc;
    background-color: #f9f9f9;
    margin-bottom: 20px;
    padding: 0 10px;
}

.activity-button {
    position: relative;

    padding: 10px 20px;
    margin: 0 5px;
    background-color: transparent;
    border: none;
    color: #333;
    cursor: pointer;
    border-bottom: 3px solid transparent;
    transition: background-color 0.3s, color 0.3s;
}

.activity-button:hover {
    background-color: #e2e2e2;
}

.activity-button.active {
    color: #007bff;
    background-color: #fff;
    border-bottom: 3px solid #007bff;
}

.content-area {
    padding: 20px;
    border: 1px solid #ccc;
    border-top: none;
    background-color: #fff;
}

.notification-badge {
    position: absolute;
    top: 0;  
    right: 0;  
    transform: translate(50%, -50%);
    width: 20px;
    height: 20px;
    background-color: red;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    border: 2px solid white;
}

