/* ChangePassword.css */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html, body {
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
    font-family: 'Roboto', Arial, sans-serif;
    overflow-x: hidden;
  }
  
  .change-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 40px);
    padding: 20px;
  }
  
  .change-password-container h2 {
    font-size: 2em;
    font-weight: bold;
    color: #4CAF50;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .change-password-container form {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 320px;
    margin: 20px 0;
  }
  
  .change-password-container form input {
    width: 100%;
    padding: 12px 15px;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .change-password-container form button {
    width: 100%;
    padding: 12px 20px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    background-color: #007BFF;
    color: white;
  }
  
  .change-password-container p {
    font-size: 1em;
    margin-top: 10px;
    text-align: center;
    color: #333;
  }
  
  .change-password-container a {
    color: #007BFF;
    text-decoration: none;
  }
  
  .change-password-container a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 360px) {
    .change-password-container h2 {
      font-size: 1.8em;
    }
  }
  