/* ================================
   A) ACTIVE LINK
================================ */
/* Removed the repeated .active-link .nav-icon and .active-link .nav-text 
   that set color: #007bff, because we override them with dark purple below. */

/* Retained hover/focus background color rule */
.active-link a:hover,
.active-link a:focus {
  background-color: #004080; /* Slightly darker blue for hover/focus (optional) */
}

/* ================================
   ADD-ITEM-MODAL
================================ */
.add-item-modal {
  position: fixed; /* Ensures the modal is positioned relative to the viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5000; /* Ensures the modal is on top of other content */
}
.add-item-modal .modal-content {
  position: relative; /* Positioned relative to the modal itself */
  max-width: 500px; /* Maximum width of the modal content */
  width: 90%; /* Responsive width */
  background: white; /* Background color for visibility */
  padding: 20px; /* Padding for aesthetic spacing */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for 3D effect */
  border-radius: 8px; /* Rounded corners */
  z-index: 5001; /* Higher than the modal backdrop to ensure it's clickable */
}
.add-item-modal .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent backdrop */
  z-index: 5000; /* Ensures it's right below the modal content */
}

/* ================================
   ALREADY-INTERESTED-NOTIFICATION
================================ */
.already-interested-notification {
  background-color: #e2e3e5; /* Neutral light grey background */
  color: #155724; /* Green text for a positive, noticeable look */
  padding: 10px 15px; /* Sufficient padding for emphasis */
  border-radius: 5px; /* Rounded corners for a soft, modern look */
  margin-top: 5px; /* Space above the notification */
  border: 1px solid #c3e6cb; /* Subtle green border matching the text */
  text-align: center; /* Centers the notification text */
}

/* ================================
   A:FOCUS, BUTTON:FOCUS
================================ */
a:focus,
button:focus {
  outline: none;
  box-shadow: none;
}

/* ================================
   APP
================================ */
body, html {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #f9f9f9;
}

.App {
  text-align: center;
  max-width: 1024px;
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  overflow: hidden;
}
.App-content {
  /* Extra top and bottom padding so content doesn't hide behind header or nav */
  padding-top: 40px;

}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* ================================
   BOTTOM-NAV-CONTAINER
================================ */
.bottom-nav-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
  background-color: #fff;
  box-shadow: 0 -2px 4px rgba(0,0,0,0.1);
  overflow: visible !important; /* sub-menu can overflow above nav */
  transition: transform 0.3s;
}

/* ================================
   BOTTOM-NAV
================================ */
.bottom-nav {
  background-color: #fff;
  box-shadow: 0 -2px 4px rgba(0,0,0,0.1);
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap to the next line if needed */
  justify-content: space-around;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  height: auto; /* Adjust height dynamically based on content */
}

.bottom-nav ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 50px;
}
.bottom-nav ul li {
  flex: 1;
  text-align: center;
}
.bottom-nav ul li a,
.bottom-nav ul li button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: none;
  background: none;
  cursor: pointer;
  width: 100%;
  color: #000; /* default label color => black */
  stroke: black;
}

/* Remove or override background highlight on hover/press/focus */
.bottom-nav ul li a:hover,
.bottom-nav ul li button:hover,
.bottom-nav ul li a:focus,
.bottom-nav ul li button:focus {
  background: none !important; 
  color: #333;
}

.bottom-nav ul li a:active,
.bottom-nav ul li button:active {
  background: none !important;
  color: #333; /* Make pressed icon label black or slightly darker, if desired */
}

.button:active,
.submit-btn:active{
  background-color: #ddd;
  transform: none !important; /* remove the translateY(1px) effect */
}

/* ================================
   BUTTON, SUBMIT-BTN
   (Removed .nav-icon from here)
================================ */
.button,
.submit-btn {
  display: flex;
  flex-direction: column; /* Ensures vertical stacking */
  align-items: center; /* Centers icon and text */
  justify-content: center; /* Centers vertically */
  text-decoration: none; /* Removes underline on links */
  color: white; /* For these buttons */
  font-size: 1.3rem; /* Icon size */
  cursor: pointer;
  transition: color 0.2s;
  width: 100%; /* Ensures all occupy the same width */
}

.button:hover,
.submit-btn:hover {
  background-color: #e7e7e7;
  color: #333;
}

/* ================================
   CARD-BODY
================================ */
.card-body {
  padding: 10px;  /* Uniform padding */
  text-align: center;
  background-color: transparent; 
}
.card-body p {
  white-space: nowrap;  /* Prevents the text from wrapping */
  overflow: hidden;  
  text-overflow: ellipsis;  
  margin-top: 4px;   
  margin-bottom: 4px;  
  font-size: 0.85rem;  
  font-weight: normal; 
  color: #757575;      
  text-align: center;  
  display: block;      
}

/* ================================
   CLOSE-BTN, .BTN
================================ */
.close-btn, .btn {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 20px;
  padding: 5px 10px;
  align-items: left;
}

/* ================================
   CUSTOM-SELECT-SIZE
================================ */
.custom-select-size {
  height: calc(2.25rem + 2px); /* Bootstrap default height for form inputs */
  padding: .375rem 1.75rem .375rem .75rem; /* Adjust padding to visually match radio buttons */
}

/* ================================
   CUSTOM-RADIO-SIZE
================================ */
.custom-radio-size {
  padding-top: .375rem;
  padding-bottom: .375rem; /* Align with dropdown height */
}

/* ================================
   ERROR-MESSAGE
================================ */
.error-message {
  color: #d32f2f; /* Red color for errors */
  text-align: center;
  padding: 10px;
  margin-top: 10px;
  background-color: #ffdddd; /* Light red background */
  border: 1px solid #d32f2f;
  border-radius: 4px;
}

/* ================================
   FILE-INPUT, FILE-INPUT-LABEL
================================ */
.file-input {
  display: block;
  width: 100%;
}
.file-input-label {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
}

/* ================================
   FORM-GROUP
================================ */
.form-group {
  margin-bottom: 15px;
}

/* ================================
   FORM-STEP
================================ */
.form-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

/* ================================
   HR (Generic Horizontal Rule)
================================ */
hr {
  border: none;
  height: 1px;
  background-color: #ccc; /* Subtle gray divider */
  margin: 10px 0; /* Spacing around divider */
}

/* ================================
   H3 (Modal content headings)
================================ */
h3 {
  font-size: 18px; 
  font-weight: bold; 
  color: #333;
  margin: 0 0 5px;
}

/* ================================
   P (Generic paragraphs)
================================ */
p {
  font-size: 16px;
  margin: 0 0 5px;
  line-height: 1.3;
  text-align: left;
  color: #666; /* Slightly lighter for less critical info */
}

/* ================================
   HEADER
================================ */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10; /* Higher z-index to ensure it stays on top of other content */
  display: flex;
  align-items: center;
  padding: 0; /* Remove extra padding so hamburger is flush left */
  background-color: #f8f8f8;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

@media (max-width: 768px) {
  .header {
    padding: 0 10px; /* Adjust padding for smaller screens if needed */
  }
}

/* ================================
   HAMBURGER-BTN
================================ */
.hamburger-btn {
  font-size: 1.3rem;
  color: black; 
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 5px; /* Tiny nudge from the screen edge */
  padding: 10px;    /* Tappable area */
  width: auto;      /* Only as wide as needed for the icon */
}
.hamburger-btn:hover {
  color: #333;
}

/* ================================
   IMAGE-PREVIEW
================================ */
.image-preview {
  width: 100%;
  height: auto;
  margin-top: 10px;
  border-radius: 4px;
}

/* ================================
   IMG-FLUID
================================ */
.img-fluid {
  max-height: 50vh; /* Ensures the image does not exceed half the viewport height */
  width: auto; /* Maintains the aspect ratio */
  max-width: 100%; /* Ensures the image does not exceed the container width */
  height: auto; /* Maintains the aspect ratio */
  margin-bottom: 5px; /* Keeps space below the image */
  border-radius: 8px; /* Aesthetic choice for rounded corners */
  object-fit: contain; /* Ensures the image is scaled properly */
}

/* ================================
   INPUT-FIELD, SELECT-FIELD, .BUTTON, .SUBMIT-BTN
================================ */
.input-field,
.select-field,
.button,
.submit-btn {
  padding: 10px;
  margin: 5px 0;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #fafafa;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.1s;
}

/* ================================
   ITEM-CARD (Original Code)
================================ */
.item-card {
  border: 1px solid #e0e0e0;  /* Softer border color for a less intrusive look */
  border-radius: 8px;  /* Slightly larger radius for a more modern feel */
  margin: 5px;
  overflow: hidden;  /* Maintains the border radius effect on images */
  padding: 0;
  width: calc(50% - 10px);  /* Calculation for two cards per row */
  box-sizing: border-box;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;  /* Smooth transition for scale and shadow */
  display: flex;
  flex-direction: column;
  background: #fff;  /* Consistent background for the whole card */
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);  /* Subtle shadow for depth */
}
.item-card:hover {
  transform: scale(1.03);  /* More subtle scaling on hover */
  box-shadow: 0 5px 15px rgba(0,0,0,0.15);  /* Enhanced shadow on hover for a "lifted" effect */
}
.item-card img {
  width: 100%;  /* Ensures the image takes up the full width of the card */
  height: auto;  /* Maintains the natural aspect ratio of the image */
  object-fit: cover;  /* Covers the area of the card if you set a fixed height */
}
/* Alternative with padding-top trick for maintaining aspect ratio */
.item-card .image-container {
  width: 100%;
  padding-top: 56.25%;  /* Aspect ratio of 16:9 */
  position: relative;
  overflow: hidden;
}
.item-card .image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Force each item to the same 50% - 10px approach */

.item-wrapper {
  position: relative;
  margin: 5px;
  width: calc(50% - 10px); /* EXACT same as .item-card had originally */
  box-sizing: border-box;   /* Prevent padding/border from shrinking content */
}

/* 
   If .item-card also sets width: calc(50% - 10px), 
   either remove it from .item-card or from item-wrapper. 
   You only want ONE place specifying 50%. 
*/

/* The tinted overlay */
.selection-overlay {
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  background-color: rgba(0, 123, 255, 0.15);
  pointer-events: none;
  border-radius: 8px; /* match .item-card corners if needed */
}

/* A small corner check icon */
.selection-check {
  position: absolute;
  top: 8px;
  left: 8px;
  color: #007BFF;
  font-size: 1.2rem;
}


/* ================================
   ITEM-DETAILS
================================ */
.item-details {
  font-size: 1rem;
  line-height: 1.5;
}
.item-label {
  font-weight: bold;
  color: #007bff; /* Use accent color to highlight labels */
}
.item-section {
  padding-bottom: 10px; /* Padding before the divider */
}
.item-name {
  font-size: 0.9rem; /* Subdued color for item label */
  color: #6c757d; 
  margin-top: 0; 
  margin-bottom: 10px; 
  display: block; 
  text-align: center; 
  overflow: hidden; 
  white-space: nowrap; 
  text-overflow: ellipsis; 
  max-width: 90%; 
  width: auto; 
}

/* ================================
   LOGO-CONTAINER .LOGO
================================ */
.logo-container .logo {
  font-weight: 600;
  font-size: 1.2rem;
  color: #333;
  text-decoration: none;
}

.logo-container {
  flex: 1;
  text-align: center;
}

/* If you want to style the anchor specifically */
.branding-link {
  text-decoration: none;
  color: inherit;
}

/* Reuse the same "branding" styles from login page */
.branding span {
  font-size: 2.0em; /* Adjust as needed */
  color: #333; /* Changed from #666 to a darker shade for better contrast */
  font-weight: 700; /* Optional: Slightly bolder */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* Optional: Subtle shadow */
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.login-icon {
  display: inline-flex; /* So icons line up nicely with text */
  vertical-align: middle; 
}

/* ================================
   LOGOUT-BUTTON
================================ */
.logout-button {
  padding: 5px 10px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.logout-button:hover {
  background-color: #c33c29;
}

/* ================================
   MAIN-NAV LI A DIV
================================ */
.main-nav li a div {
  position: relative;  
  display: inline-flex;  
  align-items: center;  
  justify-content: center;  
}

/* ================================
   MENU (Hamburger Popup)
================================ */
.menu {
  display: none;
  position: absolute;
  top: 60px; /* Just below the fixed header */
  left: 10px;
  width: 200px;
  background-color: #f9f9f9;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  z-index: 1000;
  border-radius: 6px;
  padding: 5px 0;
}
.menu.open {
  display: block;
}
.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.menu ul li a,
.menu ul li button {
  display: block;
  width: 100%;
  padding: 6px 10px;
  color: black;
  text-align: left;
  text-decoration: none;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.menu ul li a:hover,
.menu ul li button:hover {
  background-color: #ddd;
}

/* ================================
   MODAL
================================ */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Darker overlay for better focus */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  z-index: 1000;
}
.modal-content {
  background: #ffffff;
  padding: 10px 15px 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  width: 95%;
  max-width: 600px;
  position: relative;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

/* ================================
   NOTIFICATION-BADGE
================================ */
.notification-badge {
  position: absolute;
  top: -3px;
  right: -3px;
  transform: translate(25%, -25%);
  width: 20px;
  height: 20px;
  background-color: red;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  border: 2px solid white;
}

/* ================================
   NAV.MAIN-NAV UL, ETC.
================================ */
nav.main-nav ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
nav.main-nav ul li {
  display: flex;
  margin-right: 10px;  /* Reduced margin */
}
nav.main-nav ul li a,
nav.main-nav ul li button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;  
  font-size: 1.0rem;
  text-decoration: none;
}

@media (max-width: 768px) {
  nav.main-nav ul li a,
  nav.main-nav ul li button {
    padding: 8px;  
    font-size: 1.0rem;
  }
}

/* ================================
   NAV UL LI
================================ */
nav ul li {
  display: inline;
}
nav ul li a,
nav ul li button {
  display: inline-block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}
nav ul li a:hover,
nav ul li button:hover {
  background-color: #ddd;
}

/* ================================
   REGISTER-CONTAINER
================================ */
.register-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
}
.register-form input,
.register-form button {
  width: 90%;
  max-width: 300px;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
}
.register-form button {
  background-color: #007BFF;
  color: white;
  cursor: pointer;
}
.register-form button:hover {
  background-color: #0056b3;
}

/* ================================
   SHARED-DETAILS
================================ */
.shared-details {
  margin-top: 20px;
}
.shared-details h4 {
  margin-bottom: 10px;
  color: #333;
}
.shared-details ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.shared-details li {
  padding: 5px;
  border-bottom: 1px solid #ccc;
}

/* ================================
   TEXT-INPUT, TEXTAREA-INPUT
================================ */
.text-input, .textarea-input {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* ================================
   @MEDIA (MIN-WIDTH: 1024PX)
================================ */
@media (min-width: 1024px) {
  .App {
    max-width: 1024px;
    height: 1366px;  
    margin: 20px auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  body {
    background-color: #e5e5e5; /* Light gray background outside the app frame */
  }
  .modal-content {
    width: calc(100% - 60px);
    max-width: 800px;
  }
  .item-card {
    width: calc(33.33% - 10px); /* For 3 cards per row on large screens */
  }
}

/* ================================
   KEYFRAMES
================================ */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ================================
   POPUP MENU STYLING
================================ */

.post-menu {
  position: absolute;
  bottom: 70px; /* Slightly higher to accommodate the arrow */
  left: 50%;
  transform: translateX(-50%) scale(0.99);
  background: rgba(255, 255, 255, 0.99); /* Semi-transparent white */
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  z-index: 9999; /* Ensure it's above other elements */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  padding: 2px 0;
  min-width: 140px; /* Increased width for better spacing */
  opacity: 0;
  animation: fadeIn 0.3s forwards, scaleUp 0.3s forwards;
}

/* Arrow pointing to the '+' button */
.post-menu::before,
.post-menu::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px 10px 0 10px;
  border-style: solid;
}

.post-menu::before {
  border-color: rgba(0, 0, 0, 0.1) transparent transparent transparent; /* Outer arrow */
}

.post-menu::after {
  border-color: #fff transparent transparent transparent; /* Inner arrow (matching background) */
  top: 102%;
}

/* Animations */
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes scaleUp {
  to {
    transform: translateX(-50%) scale(1);
  }
}

.post-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.post-menu li {
  padding: 12px 20px;
  text-align: center;
  font-size: 1rem; 
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}

.post-menu li:hover {
  background-color: rgba(0, 123, 255, 0.1); /* Light blue hover */
  color: #007BFF; /* Blue text on hover */
}

/* ================================
   POPUP MENU CLOSE ANIMATION
================================ */

.post-menu.hide {
  animation: fadeOut 0.3s forwards, scaleDown 0.3s forwards;
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}

@keyframes scaleDown {
  to {
    transform: translateX(-50%) scale(0.95);
  }
}

/* ================================
   NAV-ICON AND NAV-TEXT
================================ */
.nav-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #6C757D !important;
    fill: #6C757D!important;
  font-size: 1.3rem;
  cursor: pointer;
  transition: color 0.2s;
  width: 100%;
}

.nav-icon > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nav-text {
  font-size: 0.75rem;
  margin-top: 4px;
  color: #000 !important;
  text-align: center;
}

/* Default (unselected) icons */
.bottom-nav ul li .nav-icon svg {
  fill: transparent !important;
  stroke: #000 !important;
  stroke-width: 1.8;
  transition: fill 0.2s, stroke 0.2s;
}

/* Hover state */
.bottom-nav ul li a:hover .nav-icon,
.bottom-nav ul li button:hover .nav-icon {
  color: #333;
}

.bottom-nav ul li a:hover .nav-icon svg,
.bottom-nav ul li button:hover .nav-icon svg {
  stroke: #333 !important;
}

/* Active (selected) state */
.active-link .nav-icon {
  color:#007BFF !important;
  fill: #007BFF !important;

}

.active-link .nav-icon svg {
  fill: #007BFF !important;
  stroke: none;
}

.active-link .nav-text {
  color: #007BFF !important;
}

/* App.css */

/* Ensure the parent div is relative (already handled inline) */

/* Adjust the badge position */
.notification-badge {
  position: absolute;
  top: -5px;  /* Adjust as needed */
  right: -5px;  /* Adjust as needed */
  transform: translate(50%, -50%);
  width: 18px;
  height: 18px;
  background-color: red;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7em;
  border: 1px solid white;
}

/* App.css */

/* Responsive adjustments for smaller screens */
@media (max-width: 390px) { /* Adjust based on actual device widths */
  .notification-badge {
      width: 16px;
      height: 16px;
      font-size: 0.6em;
      top: -3px;
      right: -3px;
  }
}

