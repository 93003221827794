.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    padding: 10px; /* Allow some padding around the modal for smaller screens */
}

.modal-content {
    background: white;
    color: #333333;  /* Dark gray for text provides better readability */

    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%; /* Full width on small screens */
    max-width: 400px; /* Limit width on larger screens */
    position: relative;
    animation: fadeIn 0.3s ease-in-out;
}

.modal-header {
    display: flex;
    justify-content: flex-end;
}

.close-btn {
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
    padding: 5px;
}

.form-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.file-input-hidden {
    display: none;
}

.file-input-label {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    display: block; /* Make the label block to fill width */
    width: 100%;

}

.file-input-label:hover {
    background-color: #0056b3;
}

.image-preview {
    width: 100%;
    max-height: 200px;
    margin-top: 10px;
    border-radius: 4px;
    object-fit: cover;
}



.voice-btn, .next-btn, .submit-btn {
    width: 100%;
    padding: 12px;
    border-radius: 4px;
    color: white;
    background-color: #007bff;
    border: none;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;

}

.voice-btn:hover, .next-btn:hover, .submit-btn:hover {
    background-color: #0056b3;
}

.switch-label {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align items to the start */
    width: 100%;
    margin: 10px 0;
    gap: 10px; /* Reduce the gap between label and switch */
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-bottom: 0; /* Remove bottom margin */
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:checked + .slider:before {
    transform: translateX(26px);
}


.recipient-dropdown {
    width: 100%;
    padding: 10px;
}

/* React Select specific enhancements */
.css-yk16xz-control, .css-1pahdxg-control {
    border-color: #0056b3; /* A consistent blue theme for focused elements */
    background-color: #ffffff; /* Ensure background remains white */
}

.css-1hwfws3 {
    background-color: #f0f0f0; /* Lighter background for selected items */
}

.css-26l3qy-menu {
    background-color: #ffffff; /* Ensuring dropdown menu is also white */
    color: #333333; /* Darker text within dropdown for readability */
}

/* Highlighting active and hover states in dropdown */
.css-yk16xz-control:hover, .css-1pahdxg-control:hover, .css-1okebmr-indicatorSeparator {
    border-color: #004095; /* Even darker blue for hovered states */
}

/* Styles for selected and active options */
.css-qc6sy-singleValue, .css-1uccc91-singleValue {
    color: #0056b3; /* Highlight the selected item with primary color */
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.text-input, .textarea-input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px; /* Ensure text is easily readable */
}

.text-input:focus, .textarea-input:focus {
    border-color: #007bff; /* Highlight focus with a blue border */
    outline: none; /* Remove default focus outline */
}

.textarea-input {
    height: 100px; /* Provide enough space for a meaningful description */
    resize: vertical; /* Allow vertical resizing only */
}

.details-container, .form-step {
    margin-top: 20px;
    padding: 15px;
    background-color: #f7f7f7; /* Light grey background to highlight the area */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Soft shadow for depth */
    width : 100%
}

.detail-label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333; /* Darker text for better readability */
}



.toggle-header {
    cursor: pointer;
    padding: 10px;
    background-color: #28a745; /* Bootstrap green for primary actions */
    color: white;
    margin-top: 5px;
    border-radius: 4px;
    font-size: 16px;
    text-align: center; 
    transition: background-color 0.2s ease;  
}

.toggle-header:hover {
    background-color: #218838; /* A darker shade of green on hover for better feedback */
}


/* Container for details to ensure it aligns with the toggle headers */
.recipient-selection {
    width: 100%;  /* Full width for alignment */
    display: flex;
    flex-direction: column;
    align-items: center;  /* Centers the content */
}

.detail-item {
    width: 100%; /* Full width */
    display: flex;
    flex-direction: column;
    align-items: center;  /* Centers the content */
}

.card {
    width: 90%;  /* Slightly less than full width for aesthetic margin */
    padding: 5px;
    margin-bottom: 2px; /* Keep tight vertical spacing */
    border: 1px solid #ddd; /* Subtle borders */
    cursor: pointer;
    border-radius: 4px;
    box-sizing: border-box; /* Include padding and border in the width */
    transition: background-color 0.2s, border-color 0.2s;
}

.card.selected {
    background-color: #e9ecef; /* Light grey background for selected cards */
    color: #333; /* Dark grey or black text for better readability */
    border-color: #adb5bd; /* A matching lighter border */
}

.card:hover {
    background-color: #dee2e6; /* A slightly darker grey on hover */
    color: #333; /* Keeping text color consistent */
}

.siri-spinner {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100px;  /* Adjust width as necessary for your design */
    height: 20px;  /* Adjust height as necessary */
    margin: 20px auto; /* Centers the spinner and adds vertical space */
}

.dot {
    width: 8px;
    height: 8px;
    background-color: #007bff; /* Blue color, you can choose any */
    border-radius: 50%;
    animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
    0%, 100% {
        transform: scale(0.75);
        opacity: 0.2;
    }
    50% {
        transform: scale(1.4);
        opacity: 1;
    }
}

.dot:nth-child(1) { animation-delay: 0s; }
.dot:nth-child(2) { animation-delay: 0.3s; }
.dot:nth-child(3) { animation-delay: 0.6s; }
.dot:nth-child(4) { animation-delay: 0.9s; }
.dot:nth-child(5) { animation-delay: 1.2s; }

.recipient-toggle {
    display: flex;
    justify-content: space-around;
    width: 100%;  /* Ensure full width for alignment */
}

.toggle-header {
    cursor: pointer;
    padding: 10px;
    background-color: #28a745; /* Bootstrap green for primary actions */
    color: white;
    border-radius: 4px;
    font-size: 16px;
    text-align: center; 
    flex: 1;  /* Make both buttons take equal space */
    margin: 5px;
}

.toggle-header:hover {
    background-color: #218838; /* A darker shade of green on hover for better feedback */
}

.recipient-details {
    width: 100%;  /* Ensure full width for content below buttons */
}

.detail-item {
    width: 100%; /* Ensure each item takes full width */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card {
    width: 90%;  /* Slightly less than full width for aesthetic margin */
    padding: 5px;
    margin-bottom: 2px; /* Keep tight vertical spacing */
    border: 1px solid #ddd; /* Subtle borders */
    cursor: pointer;
    border-radius: 4px;
    box-sizing: border-box; /* Include padding and border in the width */
    transition: background-color 0.2s, border-color 0.2s;
    text-align: center; /* Center text in cards */
}

.card.selected {
    background-color: #e9ecef; /* Light grey background for selected cards */
    color: #333; /* Dark grey or black text for better readability */
    border-color: #adb5bd; /* A matching lighter border */
}

.card:hover {
    background-color: #dee2e6; /* A slightly darker grey on hover */
    color: #333; /* Keeping text color consistent */
}

.button-container {
    display: flex;
    justify-content: space-between; /* Distribute space evenly between buttons */
    margin-top: 20px; /* Provide some top margin for spacing */
    width: 100%;  /* Ensures the container spans the full width */
    padding: 0 10px;  /* Adds padding inside the container for spacing */
}

.btn {
    flex: 1; /* Make buttons take up equal space */
    margin: 0 5px; /* Provide some space between the buttons */
    padding: 10px; /* Increase padding for better touch target size */
    font-size: 16px; /* Increase font size for better readability on mobile */
}

.button-spacer {
    width: 100px; /* Same width as buttons to maintain symmetry */
}

.btn-primary {
    background-color: #007bff; /* Primary button blue */
    color: white;
    border: none;
}

.btn-secondary {
    background-color: #6c757d; /* Secondary button gray */
    color: white;
    border: none;
}

.btn:hover {
    opacity: 0.8; /* Slight visual feedback on hover */
}



.btn-primary, .btn-secondary {
    padding: 10px 15px; /* Adjust padding to better fit icons */
    font-size: 16px; /* Icon size can be adjusted via font-size */
}
.button-placeholder {
    flex: 1; /* Takes up the same amount of space as a button */
}

.half-width {
    flex: 1; /* Each button takes up half of the container when both are displayed */
}

.full-width {
    width: 100%; /* The Back button takes full width when alone */
}

.instruction-message {
    background-color: #e9ecef; /* Light grey background */
    color: #333333; /* Dark grey text */
    padding: 15px 20px;
    border-left: 5px solid #007bff; /* Blue left border for emphasis */
    margin-bottom: 20px; /* Space below the message */
    border-radius: 4px;
    font-size: 16px;
}

.instruction-message strong {
    font-size: 18px;
}

.instruction-message p {
    margin: 5px 0 0 0;
    font-size: 14px;
}

/* Select All Network Checkbox */
.select-all-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.select-all-container input[type="checkbox"] {
    margin-right: 10px;
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.select-all-container label {
    cursor: pointer;
    font-weight: bold;
}