/* Container for the About Page */
.about-page {
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

/* Page Heading Card */
.about-heading-card {
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  text-align: center;
}

.about-page-heading {
  font-size: 28px;
  color: #007bff;
  margin-bottom: 15px;
}

.about-intro-text {
  font-size: 16px;
  color: #555555;
  line-height: 1.8;
  margin: 0;
  text-align: justify;
  max-width: 800px; /* Limit width for better readability */
  margin-left: auto;
  margin-right: auto;
}

/* About Sections Container */
.about-sections {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

/* Individual Section Card */
.about-section-card {
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about-section-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

/* Icon Styling */
.section-icon {
  font-size: 40px;
  color: #007bff;
  margin-bottom: 15px;
}

/* Section Title */
.section-title {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333333;
  text-align: center;
}

/* Section Content */
.section-content {
  font-size: 16px;
  color: #555555;
  line-height: 1.6;
  text-align: left; /* Left-align content inside cards */
}

/* Responsive Design Adjustments */
@media (max-width: 768px) {
  .about-page {
    padding: 20px;
  }

  .about-heading-card {
    text-align: center;
    padding: 15px;
  }

  .about-intro-text {
    font-size: 14px;
  }

  .about-sections {
    flex-direction: column;
    align-items: center;
  }

  .about-section-card {
    width: 100%;
    max-width: 400px;
  }
}
