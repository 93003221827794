* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
    font-family: 'Roboto', Arial, sans-serif; /* Modern and widely used font */
    overflow-x: hidden; /* Prevents horizontal scrolling */
}


.login-container, .register-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 40px); /* Adjusted for exact full height minus padding */
    padding: 20px;
    box-sizing: border-box; /* Includes padding in height calculation */
}

.branding h1 {
    font-size: 2em; /* Optimized for mobile readability */
    font-weight: bold; /* Makes the text bolder */
    color: #4CAF50; /* Subdued green */
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    margin: 0; /* Removes default margin */
}

.branding span {
    font-size: 0.9em;
    color: #666;
    font-weight: normal; /* Standard weight for tagline */
    margin-top: 5px; /* Tightened space for a compact look */
    display: flex;
    align-items: center; /* Ensures vertical alignment with the icon */
    justify-content: center; /* Centers content and icon horizontally */
    gap: 8px; /* Adds a small gap between the text and the icon */
}

.login-form, .register-form {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 320px;
    margin: 20px 0; /* Ensures space around the form is minimal but adequate */
}

.login-icon {
    display: inline-flex; /* Aligns better with inline elements */
    vertical-align: middle; /* Aligns icon with the middle of the text line */
}

.login-form input, .register-form input {
    width: 100%;
    padding: 12px 15px; /* Slightly less padding for more space efficiency */
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.login-form button, .register-form button {
    width: 100%;
    padding: 12px 20px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    background-color: #007BFF;
    color: white;
}

.register-link {
    font-size: 1em;
    text-align: center;
    margin-top: 10px; /* Reduced margin to tighten up space */
}

.register-link a {
    color: #007BFF;
    text-decoration: none;
}

.register-link a:hover {
    text-decoration: underline;
}

@media (max-width: 360px) {
    .branding h1 {
        font-size: 1.8em; /* Further reduction for smaller devices */
    }
    .branding span {
        font-size: 0.85em; /* Smaller tagline for compact appearance */
    }
}
