.statistics-container {
    display: flex;
    justify-content: space-around;
    margin-top: -10px;
    margin-bottom: 15px;

    padding: 4px;
    background-color: #f9f9f9; /* Light gray background for the stats area */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Soft shadow for a "lifted" effect */
}

.stat-card {
    flex: 1;
    text-align: center; /* Ensures all content is centered horizontally */
    padding: 4px;
    margin: 5px;
    background: #fff; /* White background for each stat */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.15); /* Subtle shadow for depth */
    display: flex; /* Enables the use of Flexbox */
    flex-direction: column; /* Stacks children vertically */
    justify-content: center; /* Centers content vertically */
}

.stat-card h3 {
    margin: 0;
    font-size: 1.25rem; /* Larger font size for emphasis */
    color: #007bff; /* Blue for the stat number */
}

.stat-card p {
    margin: 5px 0 0;
    font-size: 0.85rem;
    color: #666; /* Subtle grey for text */
    text-align: center; /* Ensures labels are centered */
}

/* Targeting the row containing the filters to reduce its bottom margin */
.row.mb-3 {
    margin-bottom: 0;  /* Reduce or remove the bottom margin */
}

/* Adjusting the padding for the alignment of radio buttons */
.custom-radio-size {
    padding-top: 0;  /* Reducing the top padding */
    padding-bottom: 0;  /* Reducing the bottom padding */
}

/* Reducing the top margin of the row containing item cards */
.row.mt-3 {
    margin-top: 0;  /* Reduce or remove the top margin */
}

.fab-button {
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 9000; 
    font-size: 1.0em;
    background-color: rgba(0, 123, 255, 0.8); /* Blue with 80% opacity */
  }

  .fab-button-wish {
    position: fixed;
    left: 10px; /* Align horizontally with the right button */
    bottom: 10px; /* Same vertical position as the item button */
    z-index: 9000;
    font-size: 1.0em;
    background-color: rgba(40, 167, 69, 0.8); /* Using RGBA for transparency, 0.8 is 80% opacity */
}

.wishlist-card {
    background: linear-gradient(to bottom, #ffffff 0%, #f9f9f9 100%);
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    border: 1px solid #e0e0e0;
    margin: 10px 5px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: calc(100% - 10px);
}

.wishlist-owner {
    font-size: 0.9em; /* Smaller size to make it less prominent */
    color: #9daebf; /* Faded blue for subtlety */
    font-weight: normal; /* Normal weight to reduce emphasis */
    margin-top: 2px; /* Position it below the wish name */
}

.wishlist-name {
    font-size: 1.2em; /* Larger size to make it the focal point */
    color: #333333;
    font-weight: bold; /* Bold to draw attention */
    margin-bottom: 5px; /* Space between the wish name and owner name */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.wishlist-description {
    font-size: 0.9em;
    color: #666666;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-top: 5px;
}




/* Container for the buttons, giving it a slight separation and alignment */
/* Unified Toolbar Container */
.controls-toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 5px; /* Reduced from 10px */
    margin-bottom: 5px; /* Reduced from 10px */
}

/* Segmented Control */
.segmented-control {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 20px;
    overflow: hidden;
}

.segment-button {
    flex: 1;
    padding: 5px 8px; /* Reduced padding */
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 0.85rem; /* Slightly smaller font */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, color 0.3s;
}

.segment-button.active {
    background-color: #007bff;
    color: white;
}

.segment-button:hover:not(.active) {
    background-color: #e2e2e2;
}


/* User Scope Toggle */
.user-scope-toggle {
    display: flex;
    align-items: center;
    gap: 5px; /* Reduced gap */
    margin-bottom: -10px; /* Reduced from 10px */

}

/* Responsive Adjustments */
@media (max-width: 600px) {
    .segmented-control {
        width: 100%;
    }

    .user-scope-toggle {
        width: 100%;
        justify-content: flex-start;
    }
}

/* Optional: Icon Styling */
.segment-button svg {
    margin-right: 5px;
    font-size: 1rem; /* Adjust icon size */
}

/* Additional styles for the content area to connect it visually with the active tab */
.content-area {
    border: 1px solid #ccc;
    border-top: none; /* Removes the top border to make it seem continuous from the tabs */
    padding: 20px;
    background-color: #fff;
}

.switch-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: -5px; /* Reduced from -10px to -5px */
    font-size: 0.9em;
    margin-bottom: -5px; /* Reduced from -10px to -5px */

    gap: 8px; /* Slightly reduced gap */
}


.switch {
    position: relative;
    display: inline-block;
    width: 50px; /* Reduced width */
    height: 24px; /* Reduced height */
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 24px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:checked + .slider:before {
    transform: translateX(26px);
}

.blurred {
    filter: blur(3px); /* Slight blur to obscure content but keep it somewhat readable */
    opacity: 0.4; /* Slight transparency for a tantalizing glimpse */
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Subtle shadow to enhance readability */
    transition: all 0.3s ease; /* Smooth transition for visual appeal */
}


.preview-notification {
    background-color: #fff7db; /* Softer yellow background */
    color: #856404; /* Deep brown text for better contrast */
    border: 1px solid #ffeeba; /* Subtle border */
    padding: 12px 20px; /* Slightly larger padding for balanced spacing */
    margin-bottom: 20px; /* Space below the notification */
    border-radius: 8px; /* Rounded corners for a modern look */
    font-size: 16px; /* Slightly larger text for readability */
    display: flex;
    justify-content: space-between; /* Align text and close button */
    align-items: center; /* Vertically center align content */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.preview-notification strong {
    font-weight: bold;
}

.preview-notification span {
    flex: 1; /* Allow text to take up available space */
    text-align: center; /* Center-align the text */
}

.close-notification {
    background: none;
    border: none;
    font-size: 18px; /* Slightly larger close button */
    color: #856404;
    cursor: pointer;
    font-weight: bold;
    margin-left: 10px;
    padding: 0; /* Remove extra padding */
}

.close-notification:hover {
    color: #533f03; /* Darker brown on hover */
    transform: scale(1.1); /* Slight zoom effect for better feedback */
    transition: color 0.2s ease, transform 0.2s ease; /* Smooth transition */
}

.login-link {
    color: #007bff; /* Blue color for links */
    font-weight: bold; /* Slightly bold to make it stand out */
    text-decoration: none; /* Remove underline for a cleaner look */
    margin-left: 5px; /* Space between the text and the link */
    transition: color 0.2s ease; /* Smooth transition for hover effect */
}

.login-link:hover {
    color: #0056b3; /* Darker blue on hover */
    text-decoration: underline; /* Add underline on hover for clarity */
}
