body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.feedback-container {
  max-width: 600px;
  margin: 20px auto;
  padding: 10px 15px;
  box-sizing: border-box;
}

.intro-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.intro-text {
  font-size: 16px;
  color: #007bff;
  text-align: center;
  margin-bottom: 10px;
  line-height: 1.4;
}

.toggle-form-button {
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
}

.toggle-form-button:hover {
  background-color: #218838;
}

.feedback-form {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 20px;
  animation: fadeIn 0.3s ease-in-out;
}

.feedback-form .form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.feedback-form .form-group textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  font-family: inherit;
  resize: vertical;
  min-height: 80px;
}

.feedback-form .checkbox-group {
  flex-direction: row;
  align-items: center;
}

.feedback-form .checkbox-group input[type="checkbox"] {
  margin-right: 8px;
}

.submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 100%;
}

.submit-button:hover {
  background-color: #0056b3;
}

.submit-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.sort-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  gap: 10px;
  flex-wrap: wrap;
}

.sort-button {
  background: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 25px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}

.sort-button:hover {
  background-color: #e0e0e0;
}

.sort-button.active {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.list-heading {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

.feedback-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.feedback-item {
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.feedback-content {
  margin-bottom: 10px;
}

.feedback-text {
  margin: 0 0 5px;
  font-size: 14px;
  color: #333;
  line-height: 1.4;
}

.feedback-meta {
  font-size: 12px;
  color: #999;
  margin: 0;
}

.feedback-actions {
  display: flex;
  justify-content: flex-start;
}

.upvote-button {
  background: #e7f1ff;
  border: 1px solid #007bff;
  border-radius: 25px;
  padding: 6px 12px;
  font-size: 14px;
  color: #007bff;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
  display: flex;
  align-items: center;
}

.upvote-button:hover:not(:disabled) {
  background-color: #d0e6ff;
}

.upvote-button.upvoted {
  background-color: #007bff;
  color: #fff;
}

.upvote-button:disabled {
  cursor: not-allowed;
}

.response-message {
  margin-bottom: 15px;
  padding: 10px 15px;
  border-radius: 4px;
  text-align: center;
}

.response-message.success {
  background-color: #d4edda;
  color: #155724;
}

.response-message.error {
  background-color: #f8d7da;
  color: #721c24;
}

.response-message.info {
  background-color: #d1ecf1;
  color: #0c5460;
}

.loading-state {
  text-align: center;
  font-size: 14px;
  color: #666;
  margin-top: 20px;
}

.no-feedback {
  text-align: center;
  color: #777;
  font-size: 14px;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .feedback-container {
    padding: 10px;
  }

  .sort-buttons {
    flex-direction: column;
    gap: 8px;
  }

  .feedback-item {
    padding: 12px;
  }
}

@media (max-width: 480px) {
  .feedback-container {
    padding: 10px;
  }

  .toggle-form-button {
    width: 100%;
    justify-content: center;
  }

  .sort-buttons {
    flex-direction: column;
    gap: 8px;
  }

  .feedback-form .form-group textarea {
    font-size: 14px;
  }

  .submit-button {
    font-size: 14px;
    padding: 10px;
  }

  .upvote-button {
    font-size: 13px;
    padding: 6px 10px;
  }

  .feedback-text {
    font-size: 13px;
  }

  .feedback-meta {
    font-size: 11px;
  }
}
