/* src/pages/FAQ.css */

.faq-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  color: #333;
  margin-top: 20px;
}

.faq-section {
  margin-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
}

.section-header.active {
  color: #007bff;
}

.section-title {
  font-size: 1.5rem;
  margin: 0;
}

.toggle-icon {
  font-size: 1rem;
  transition: transform 0.3s;
}

.section-content {
  margin-top: 10px;
}

.faq-question {
  margin-bottom: 10px;
}

.question-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 5px 0;
}

.question-header.active {
  color: #007bff;
}

.question-title {
  font-size: 1.1rem;
  margin: 0;
}

.faq-answer {
  padding: 5px 15px;
  font-size: 1rem;
  line-height: 1.5;
  color: #555;
}

.faq-answer strong {
  color: #333;
}

.faq-section:last-child {
  border-bottom: none;
}

@media (max-width: 600px) {
  .faq-page {
      padding: 10px;
  }

  .section-title {
      font-size: 1.3rem;
  }

  .question-title {
      font-size: 1rem;
  }

  .faq-answer {
      font-size: 0.95rem;
  }
}
