/* Admin.css */

.admin-dashboard {
  padding: 10px;
  margin-top: 10px;
}

/* Collapsible Sections */
.collapsible-section {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 15px;
  background-color: #fff;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}

.collapsible-section:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

/* Section Header */
.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  cursor: pointer;
  background-color: #f7f7f7;
  transition: background-color 0.3s ease;
}

.section-header.open {
  background-color: #e0f7fa;
}

.section-title {
  margin: 0;
  font-size: 20px;
  color: #333;
}

.toggle-icon {
  font-size: 18px;
  color: #555;
}

/* Section Content */
.section-content {
  padding: 15px 20px;
}

/* Activity Trends Styles */
.activity-trends .controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.time-frame-button, .user-select {
  padding: 8px 12px;
  background-color: #ccc;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 0.85rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.time-frame-button.active {
  background-color: #007bff;
  color: white;
}

.time-frame-button:hover:not(.active) {
  background-color: #b3b3b3;
}

.user-select {
  padding: 8px 12px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 20px;
}

.user-select:hover {
  border-color: #007bff;
}

.charts {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.chart-container h4 {
  text-align: center;
  margin-bottom: 10px;
  color: #333;
}

.no-data {
  text-align: center;
  color: #777;
  font-size: 14px;
}

/* Preapprove Email Styles */
.preapprove-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.preapprove-input {
  padding: 10px;
  width: 100%;
  max-width: 400px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.preapprove-button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.preapprove-button:hover:not(:disabled) {
  background-color: #218838;
}

.preapprove-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.success-message {
  color: green;
  margin-top: 10px;
  font-size: 14px;
}

.error-message {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .admin-dashboard {
    padding: 10px;
  }

  .section-title {
    font-size: 18px;
  }

  .time-frame-button, .user-select {
    font-size: 0.8rem;
    padding: 6px 10px;
  }

  .chart-container h4 {
    font-size: 16px;
  }

  .preapprove-button {
    font-size: 13px;
    padding: 8px 16px;
  }

  .preapprove-input {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .collapsible-section {
    margin-bottom: 10px;
  }

  .section-header {
    padding: 12px 15px;
  }

  .section-title {
    font-size: 16px;
  }

  .toggle-icon {
    font-size: 16px;
  }

  .charts {
    gap: 15px;
  }

  .preapprove-form {
    align-items: stretch;
  }

  .preapprove-button {
    padding: 8px 16px;
    font-size: 13px;
  }

  .preapprove-input {
    padding: 8px;
    font-size: 14px;
  }
}
