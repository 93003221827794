body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scrolling */
    font-family: Arial, sans-serif;
    background-color: #f5f5f5; /* Light background for overall page */
}

/* Container styling */
.settings-container {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px 10px; /* Reduced horizontal padding from 20px to 10px */
    box-sizing: border-box;
}


/* Tabs Navigation */
.tabs {
    display: flex;
    flex-direction: column;
    align-items: stretch; /* Ensure full-width buttons */
    margin-bottom: 30px;
    gap: 20px;
    width: 100%; /* Ensure full width */
}

.tab-row {
    width: 100%; /* Remove max-width to utilize full horizontal space */
    margin: 0; /* Remove auto margins */
    padding: 0; /* Remove any default padding */
}


.tab-button {
    width: 100%;
    padding: 20px 12px; /* Reduced horizontal padding from 24px to 12px */
    font-size: 17px;
    font-weight: 500;
    border-radius: 8px;
    background-color: #f1f1f1;
    color: #333;
    transition: background-color 0.3s, box-shadow 0.3s, color 0.3s;
    display: flex;
    align-items: center;
    gap: 6px; /* Reduced gap between icon and text */
    text-align: left;
    border: none; /* Removed border */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* More pronounced shadow */
    min-height: 70px; /* Maintained min-height for fatter appearance */
    cursor: pointer; /* Pointer cursor for better UX */
}


.tab-button:hover {
    background-color: #e0e0e0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

.tab-button.active {
    background-color: #007bff;
    color: #fff;
    box-shadow: 0 6px 12px rgba(0, 123, 255, 0.3); /* Stronger shadow for active tab */
}

/* Section Container */
.section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

/* Cards */
.card {
    width: 100%;
    max-width: 700px; /* Increased max-width for wider boxes */
    padding: 15px 20px; /* Reduced padding for crisper look */
    border: none; /* Removed border for a cleaner look */
    border-radius: 10px; /* Slightly more rounded corners */
    background: #fff; /* White background for contrast */
    cursor: default;
    text-align: left; /* Align text to the left for better readability */
    box-sizing: border-box;
    margin-bottom: 15px; /* Slightly reduced margin for tighter spacing */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow for depth */
    transition: transform 0.2s, box-shadow 0.2s; /* Smooth hover effects */
}

.card:hover {
    transform: translateY(-1px); /* Slight lift on hover */
    box-shadow: 0 4px 8px rgba(0,0,0,0.15); /* Slightly stronger shadow on hover */
}

/* Connection Cards */
.connection-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0; /* Reduced vertical padding */
    border-bottom: 1px solid #eee; /* Light separator between connection items */
}

.connection-card:last-child {
    border-bottom: none; /* Remove border from the last item */
}

/* Card Headings */
.card h6 {
    margin-bottom: 5px; /* Reduced margin for crisper look */
    font-size: 16px; /* Slightly smaller font size */
    color: #333;
}

.card p {
    margin: 5px 0;
    color: #555;
}

/* Buttons */
.unsubscribe-button, .subscribe-button {
    padding: 8px 16px; /* Reduced padding for crisper buttons */
    font-size: 14px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s;
}

.unsubscribe-button {
    background-color: #dc3545;
    color: white;
}

.unsubscribe-button:hover {
    background-color: #c82333;
}

.subscribe-button {
    background-color: #28a745;
    color: white;
}

.subscribe-button:hover {
    background-color: #218838;
}

/* Switch styling (no changes needed) */
.react-switch {
    vertical-align: middle;
    margin-left: 10px;
}

/* Search and button adjustments */
.search-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
    margin: 0 auto 25px;
    box-sizing: border-box;
}

.search-input,
.search-button {
    width: 100%;
    margin: 8px 0;
    font-size: 16px;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 5px;
}

.search-input {
    border: 1px solid #ccc;
}

.search-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.search-button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
}

.search-button:hover:not(:disabled) {
    background-color: #0056b3;
}

/* Response message */
.response-message {
    color: #28a745;
    margin-bottom: 15px;
    text-align: center;
}

/* Pending requests container */
.pending-requests-heading {
    font-size: 18px;
    color: #333;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: left;
    width: 100%;
    max-width: 700px;
}

.no-requests {
    color: #777;
    text-align: center;
    margin-bottom: 20px;
}

.pending-requests {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 700px;
    box-sizing: border-box;
}

.request-card {
    width: 100%;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 12px 15px; /* Reduced padding for crisper look */
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.request-card p {
    margin-bottom: 8px; /* Reduced margin */
    color: #555;
}

.request-actions {
    display: flex;
    justify-content: space-between;
}

.accept-button,
.decline-button {
    flex: 1;
    margin: 0 5px;
    padding: 8px 0; /* Reduced padding */
    font-size: 14px;
    border-radius: 4px;
    border: none;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
}

.accept-button {
    background-color: #28a745;
}

.accept-button:hover {
    background-color: #218838;
}

.decline-button {
    background-color: #dc3545;
}

.decline-button:hover {
    background-color: #c82333;
}

/* Loading Indicator */
.loading {
    text-align: center;
    font-size: 18px;
    color: #555;
    margin-top: 50px;
}

/* Welcome Message Styling */
.welcome-message {
    text-align: center;
    font-size: 18px;
    color: #777;
    padding: 20px;
    max-width: 700px; /* Align with `.tab-row` max-width */
    margin: 0 auto;
}

/* Animations */
.animated-div {
    overflow: hidden;
}

/* Responsive Design */
@media (max-width: 1200px) {
    .settings-container {
        max-width: 90%;
        padding: 25px 8px; /* Further reduce horizontal padding */
    }

    .card {
        max-width: 600px;
    }

    .tabs {
        gap: 15px;
    }

    .tab-button {
        font-size: 16px;
        padding: 18px 10px; /* Adjusted padding for smaller screens */
        gap: 6px;
    }
}

@media (max-width: 768px) {
    .tab-button {
        font-size: 16px;
        padding: 18px 8px; /* Further reduced padding */
    }

    .tab-row {
        max-width: 100%; /* Ensure full width */
    }

    .card {
        max-width: 90%;
    }

    .pending-requests-heading,
    .search-container,
    .pending-requests {
        max-width: 90%;
    }
}

@media (max-width: 480px) {
    .tab-button {
        padding: 16px 6px; /* Minimal horizontal padding */
        font-size: 15px;
        gap: 5px;
    }

    .tab-row {
        max-width: 100%;
    }

    .card {
        max-width: 100%;
    }

    .search-container {
        padding: 0 10px;
    }

    .pending-requests-heading,
    .search-container,
    .pending-requests {
        max-width: 100%;
    }

    .accept-button,
    .decline-button {
        font-size: 13px;
        padding: 8px 0;
    }

    .tab-button .svg-inline--fa {
        width: 18px; /* Adjust icon size for smaller screens */
        height: 18px;
    }
}

/* Notification Badge */
/* Settings.css */

.notification-badge {
    position: absolute;
    top: -5px; /* Adjust as needed */
    right: -10px; /* Adjust as needed */
    transform: translate(50%, -50%);
    width: 18px;
    height: 18px;
    background-color: red;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.7em;
    border: 1px solid white;
  }
  
  .tab-content-wrapper {
    position: relative; /* To position the badge absolutely within this container */
    display: inline-block; /* Ensure proper positioning */
  }
  
  .find-connect-badge {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #ffc107; /* Example color */
    color: white;
    border-radius: 12px;
    display: inline-block;
    font-size: 0.9em;
  }
  
 /* Responsive Adjustments */
@media (max-width: 600px) {
    .tab-button {
      font-size: 0.9em;
      padding: 8px 12px;
    }
  
    .notification-badge {
      width: 16px;
      height: 16px;
      font-size: 0.6em;
      top: 3px;
      right: 8px;
    }
  
    .find-connect-badge {
      font-size: 0.8em;
    }
  }