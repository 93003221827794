.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .modal-content {
    background: #fff;
    border-radius: 12px;
    padding: 20px;
    width: 90%;
    max-width: 400px;
    position: relative;
    overflow-y: auto;
    max-height: 90vh;
  }
  
  .modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
    position: relative;
  }
  
  .modal-header h2 {
    font-size: 1.2rem;
    margin: 0;
  }
  
  .close-btn, .btn-danger {
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    color: #747474;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: color 0.2s;
  }
  
  .close-btn {
    right: 0;
  }
  
  .btn-danger {
    left: 0;
  }
  
  .close-btn:hover, .btn-danger:hover {
    color: #5a5a5a;
  }
  
  .item-details {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .transfer-complete, .transfer-section, .interested-users-section, .shared-details {
    width: 100%;
    margin-bottom: 5px;
    padding: 2px;
    background-color: #f8f8f8;
    border-radius: 8px;
  }
  
  .transfer-complete p{
    background: #34a853;
    color: white;
    text-align: center;
  }
  
  .interested-users-section h4, .shared-details h4 {
    margin-bottom: 10px;
    font-size: 1rem;
}

.user-buttons-container {
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Center buttons horizontally */
    gap: 10px; /* Space between buttons */
}

.user-btn {
    background-color: #34a853;
    color: white;
    border: none;
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 20px;
    font-size: 0.9rem;
    transition: background-color 0.2s, transform 0.2s;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    width: 100%; /* Make button stretch to fill the container */
    text-align: center; /* Ensure text is centered */
}

.user-btn:hover {
    background-color: #2c8b43;
    transform: translateY(-2px);
}

  
  .shared-details ul {
    list-style: none;
    padding: 0;
  }
  
  .shared-details li {
    background: #f9f9f9;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 5px;
    font-size: 0.9rem;
  }
  
  .item-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 15px;
  }
  
  .item-info {
    text-align: left;
    width: 100%;
  }
  
  .item-name, .item-id, .item-description, .item-owner, .item-date {
    font-size: 0.9rem;
    margin-bottom: 10px;
  }
  
  .item-name {
    font-weight: bold;
    font-size: 1rem;
  }
  
  .btn-interest {
    width: 100%;
    padding: 10px;
    border-radius: 20px;
    font-size: 0.9rem;
    margin-top: 15px;
    transition: all 0.2s;
    background: #e74c3c;
    color: #ffffff;
    border: none;  
}

.btn-interest-expressed, .btn-interest-approved {
    width: 100%;
    padding: 10px;
    border-radius: 20px; 
    font-size: 0.9rem;
    margin-top: 15px;
    transition: all 0.2s;
    border: none;  
    background: #bdc3c7; 
    color: #ffffff; 
    cursor: default; 
}
  
  .btn-interest:hover {
    background: #c0392b;
  }
  
  .btn-interest-expressed {
    background: #3498db;
    color: #ffffff;
    cursor: not-allowed;
  }

  .btn-interest-approved {
    background: #2c8b43;
    color: #ffffff;
    cursor: not-allowed;
  }

  .btn-confirm-transfer {
    background: #3498db;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 20px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .btn-confirm-transfer:hover {
    background: #2980b9;
  }

  .blur-content {
    filter: blur(3px); /* Slight blur to obscure content but keep it somewhat readable */
    opacity: 0.4; /* Slight transparency for a tantalizing glimpse */
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Subtle shadow to enhance readability */
    transition: all 0.3s ease; /* Smooth transition for visual appeal */
}

.image-tip {
  font-size: 0.75rem;
  color: #555;
  text-align: center;
  margin-top: 5px;
}